<template>
  <!-- 详情与编辑、添加都放同一页 -->
  <div>
    <div class="pad-container mb-10">
      <p>门店信息</p>
      <el-form class="mt-24" label-width="100px" :model="merchant_form" ref="merchant_form" >
        <el-form-item label="选择门店">
          <el-select placeholder="请选择门店" class="form-input" v-model="merchant_form.shopId" @change="chooseStore">
            <el-option
              v-for="(item, index) in storeList"
              :key="index"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p>基本信息</p>
      <el-form
        class="mt-24"
        label-width="100px"
        :model="merchant_form"
        :rules="rules"
        ref="merchant_form">
        <el-form-item label="商户名称" prop="merchantName">
          <el-input
            class="form-input"
            maxlength="15"
            show-word-limit
            placeholder="请输入商户名称"
            v-model="merchant_form.merchantName"
          ></el-input>
        </el-form-item>
        <el-form-item label="商户分类" prop="merchantClassifyId">
          <el-select
            placeholder="请选择类别"
            class="form-input"
            v-model="merchant_form.merchantClassifyId"
          >
            <el-option
              v-for="(item, index) in storeTypeList"
              :key="index"
              :label="item.classifyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系方式" prop="merchantPhone">
          <el-input
            class="form-input"
            placeholder="手机格式：13912345678 固话格式：0755-1234567或010-12345678"
            clearable
            :maxlength="12"
            v-model="merchant_form.merchantPhone"
          ></el-input>
        </el-form-item>
        <el-form-item label="商户楼层" prop="floor">
          <el-select
            placeholder="请选择楼层（选择楼层前需选择门店）"
            class="form-input"
            v-model="merchant_form.floor">
            <el-option
              v-for="(item, index) in floorList"
              :key="index"
              :label="item.floorName"
              :value="item.floorName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门牌号" prop="door">
          <el-input class="form-input" placeholder="仅输入门牌号，如7003或A1-1003" v-model="merchant_form.door"></el-input>
        </el-form-item>
        <el-form-item label="商户LOGO" prop="merchantLogo">
          <UploadPics :uploadType="'logo'" :imgLimit="1" :imgSize="'88*88'" :imgsPreview="logoImgStr" @getImgData="getImgData"/>
        </el-form-item>
        <el-form-item label="商户主图" prop="imgs">
          <UploadPics :uploadType="'main'" :imgLimit="1" :imgSize="'750*450'" :imgsPreview="mainImgStr" @getImgData="getImgData"/>
        </el-form-item>
        <el-form-item label="商户辅图" prop="subImgs">
          <UploadPics :uploadType="'pics'" :imgLimit="6" :imgSize="'750*450，图片底部预留60px'" :imgsPreview="subImgStr" @getImgData="getImgData"/>
        </el-form-item>
        <el-form-item label="店铺排名" prop="rankSort">
          <el-select
            placeholder="请选择店铺排名"
            class="form-input"
            filterable
            clearable
            v-model="merchant_form.rankSort"
          >
            <el-option
              v-for="(item, index) in 10"
              :key="index"
              :label="item"
              :value="(index + 1)"
            ></el-option>
          </el-select>
          <p class="c-9">
            注：选择数字1-10，1代表放在最前面，顺序依次排列，未选择默认为排名在10以后。
          </p>
        </el-form-item>
        <el-form-item label="详情介绍" prop="base_price">
          <tinymce v-model="merchant_form.merchantDetail" :height="300" />
        </el-form-item>
      </el-form>
    </div>
    <div class="flx-row ali-c js-fe mt-50">
      <el-button plain class="form-button" @click="$router.go(-1)">取消</el-button>
      <el-button
        class="form-button"
        type="primary"
        @click="submit">确定</el-button>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import UploadPics from "@/components/UploadPics"
import request from "@/utils/request";
// import { addMerchant, updateMerchant } from "@/api/service_center/merchants.js";
export default {
  components: {
    Tinymce,
    UploadPics
  },
  data() {
    return {
      merchant_form: {
        shopId: "",
        merchantName: "",
        merchantClassifyId: "",
        floor: "",
        door: "",
        merchantLogo: "",
        imgs: "",
        subImgs: "",
        merchantPhone: "",
        rankSort: "",
        merchantDetail: ""
      },
      storeList: [],
      storeTypeList: [],
      floorList: [],
      rules: {
        shopId: [{ required: true, message: "门店不能为空", trigger: 'blur' }],
        merchantName: [{ required: true, message: "商户名称不能为空", trigger: 'blur'}],
        merchantClassifyId: [{ required: true, message: "商户分类不能为空"}],
        merchantPhone: [
          { required: true, message: "联系方式不能为空", trigger: 'blur' }, 
          { pattern: /^(([1]([3-9])[0-9]{9})|([0]\d{2}-\d{8})|([0]\d{3}-\d{7}))$/, message: "号码格式有误", trigger: 'blur'}
        ],
        floor: [{ required: true, message: "商户楼层不能为空" }],
        door: [{ required: true, message: "门牌号不能为空", trigger: 'blur' }],
        merchantLogo: [{ required: true, message: "商户LOGO图不能为空"}],
        imgs: [{ required: true, message: "商户主图不能为空" }],
        subImgs: [{ required: true, message: "商户辅图不能为空" }],
      },
      addFlag: true,
      merchantId: "",
      logoImgStr: "",
      mainImgStr: "",
      subImgStr: "",
    };
  },
  mounted() {
    this.handleOpeType()
    this.getStoreList()
    this.getStoreTypeList()
  },
  methods: {
    //获取门店列表
    getStoreList() {
      request({
        url: "/goods-service/store/listOfPage",
        method: "get",
      }).then(res => {
        this.storeList = res.data.body.list
      })
    },
    //选择门店获取楼层信息
    chooseStore(e) {
      this.getFloorList(e)
      this.merchant_form.floor = ""
    },
    //获取门店楼层
    getFloorList(id) {
      request({
        url: "/goods-service/store/listOfPage4?id=" + id,
        method: "post",
      }).then(res => {
        this.floorList = res.data.body
      })
    },
    getStoreTypeList() {
      request({
        url: "/customer-service/lrMerchant/classifyAll",
        method: "get"
      }).then(res => {
        this.storeTypeList = res.data.body
      });
    },
    //判断操作类型-新增/编辑
    handleOpeType() {
      let params = this.$route.params
      this.addFlag = params.addFlag != undefined ? params.addFlag : sessionStorage.getItem('ADD_FLAG')
      if(params.addFlag != undefined) sessionStorage.setItem('ADD_FLAG', params.addFlag)
      if(params.merchantId) sessionStorage.setItem('MERCHANT_ID', params.merchantId)
      if(String(this.addFlag) == 'false') {
        this.merchantId = params.merchantId || sessionStorage.getItem('MERCHANT_ID')
        this.getMerchantData(this.merchantId)
      }
    },
    //获取商户详情
    getMerchantData(id) {
      request({
        url: `/customer-service/lrMerchant/merchantDetail/${id}`,
        method: "get"
      }).then(res => {
        this.merchant_form = res.data.body
        if(this.merchant_form.shopId) this.getFloorList(this.merchant_form.shopId)
        this.logoImgStr = this.merchant_form.merchantLogo
        this.mainImgStr = this.merchant_form.imgs
        this.subImgStr = this.merchant_form.subImgs
      })
    },
    getImgData(imgData, type) {
      if(type == 'pics') this.merchant_form.subImgs = imgData.subImgs
      if(type == 'main') this.merchant_form.imgs = imgData.mainImg
      if(type == 'logo') this.merchant_form.merchantLogo = imgData.logoImg
    },
    submit() {
      this.$refs['merchant_form'].validate(valid => {
        if(!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: 'warning'
          })
          return
        }
        this.submitForm()
      })
    },
    submitForm() {
      let data = this.merchant_form;
      let url = String(this.addFlag) == 'true'
        ? "/customer-service/lrMerchant/addMerchant"
        : "/customer-service/lrMerchant/updateMerchantInfo";
      if(String(this.addFlag) == 'false') data.merchantId = this.merchantId
      request({
        url: url,
        method: "post",
        data
      }).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        })
        this.$router.go(-1)
      });
    },
  }
};
</script>
<style lang="scss" scoped></style>
